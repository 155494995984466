.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  font-size: 1.15rem;
  border-radius: 8px;
  text-align: center;
  max-width: 600px;
}
h2 {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: bold;
}

p {
  margin: 0 auto;
  width: 90%;
  font-size: 1.1rem;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
